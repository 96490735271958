import React from 'react'
import styles from './Hero.module.css'
import Content from './Content'

function Hero({ main, heading, children, ...props }) {
  return (
    <div className={main ? styles.mainHero : styles.hero}>
      <Content>
        <h1 className={styles.heading}>{heading}</h1>
        {children}
      </Content>
    </div>
  )
}

export default Hero
