import React from 'react'
import SEO from '../components/Blocks/SEO'
import About from '../components/Pages/About'

function AboutPage(props) {
  return (
    <React.Fragment>
      <SEO
        title="Om guiden till de bästa casinosidorna på nätet"
        description="CasinoIndex.se – Det är vi som guidar dig till de bästa casinosidorna på nätet."
      />
      <About />
    </React.Fragment>
  )
}

export default AboutPage
