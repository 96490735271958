import React from 'react'
import { Link } from 'gatsby'
import styles from './About.module.css'
import Page from '../Layout/Page'
import Header from '../Layout/Header'
import Hero from '../Blocks/Hero'
import Content from '../Blocks/Content'

function About(props) {
  return (
    <React.Fragment>
      <Header>
        <Hero main heading="Det här är CasinoIndex">
          <p className={styles.preamble}>
            CasinoIndex.se är din guide till de bästa svenska casinosidorna på
            nätet.
          </p>
        </Hero>
      </Header>
      <Page>
        <section className={styles.mission}>
          <Content>
            <h2>Vårt syfte</h2>
            <p>
              Syftet med{' '}
              <Link className={styles.link} to="/">
                CasinoIndex.se
              </Link>{' '}
              är att guida besökaren till de bästa svenska casinosidorna på
              nätet idag. CasinoIndex.se tar fram oberoende, seriösa recensioner
              och jämförelser av{' '}
              <a
                rel="nofollow"
                className={styles.link}
                href="https://www.spelinspektionen.se/licensansokan/bolag-med-spellicens/"
              >
                casinosidor som alla innehar licens
              </a>{' '}
              enligt den svenska spellagen (2018:1138).
            </p>
          </Content>
        </section>
      </Page>
    </React.Fragment>
  )
}

export default About
